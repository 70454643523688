import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import { icon } from './floating-icon.module.scss';

const FloatingIcon = ({ imgData, className = '' }) => {
    return (
        <span className={`${icon} ${className}`}>
            <GatsbyImage image={getImage(imgData)} alt="Pływająca ikona" />
        </span>
    );
};

export default FloatingIcon;
