// extracted by mini-css-extract-plugin
export var aboutHeart = "about-module--about-heart--8AUbQ";
export var aboutHeartline = "about-module--aboutHeartline--QmCEK";
export var aboutHimPhoto__wrapper = "about-module--about-him-photo__wrapper--XsyYY";
export var aboutHimText = "about-module--about-him-text--5WHae";
export var aboutPhoto = "about-module--about-photo--Y8FhQ";
export var aboutShePhoto__wrapper = "about-module--about-she-photo__wrapper--cLTT8";
export var aboutSheText = "about-module--about-she-text--KAmll";
export var aboutText__content = "about-module--about-text__content--Og9Er";
export var aboutText__title = "about-module--about-text__title--sNr0Q";
export var aboutWrapper = "about-module--about-wrapper--vYImW";
export var banner = "about-module--banner--vs9hp";
export var beat = "about-module--beat--ZwFzu";
export var biceps = "about-module--biceps--BZ-Yx";
export var button = "about-module--button--IQB6C";
export var cat = "about-module--cat--KUtja";
export var diamond = "about-module--diamond--StZvY";
export var emotics = "about-module--emotics--gLQhp";
export var fire = "about-module--fire--sIgTu";
export var hero = "about-module--hero--wPc7l";
export var hero__content = "about-module--hero__content--Wppmd";
export var moreButton = "about-module--more-button--KTxd9";
export var moreVideosWrapper = "about-module--more-videos-wrapper--icWF6";
export var nails = "about-module--nails--WJKoT";
export var reasons = "about-module--reasons--7lE9Z";
export var reasonsBox = "about-module--reasons-box--Mm0Oe";
export var reasonsBox__item = "about-module--reasons-box__item--nEui0";
export var reasonsBox__item__counter = "about-module--reasons-box__item__counter--S3elb";
export var reasonsBox__list = "about-module--reasons-box__list--ArODg";
export var reasonsBox__title = "about-module--reasons-box__title--rJLLq";
export var smile = "about-module--smile--ovBTm";
export var social = "about-module--social--1pQZQ";
export var socialColumn = "about-module--socialColumn--Ewdol";
export var socialCount = "about-module--socialCount--JEOYT";
export var socialIcon = "about-module--socialIcon--z3RYF";
export var subtitle = "about-module--subtitle--prOIF";
export var title = "about-module--title--LlgiW";
export var trainers = "about-module--trainers--lWG0I";
export var video = "about-module--video--ycGZO";