import React from 'react';
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import {
    title,
    reasonsBox,
    banner,
    video,
    button,
    subtitle,
    emotics,
    aboutHeart,
    aboutHeartline,
    aboutHimText,
    aboutHimPhoto__wrapper,
    aboutPhoto,
    aboutShePhoto__wrapper,
    aboutSheText,
    aboutText__content,
    aboutText__title,
    aboutWrapper,
    hero,
    hero__content,
    biceps,
    cat,
    diamond,
    fire,
    nails,
    smile,
    moreButton,
    moreVideosWrapper,
    reasons,
    reasonsBox__item,
    reasonsBox__item__counter,
    reasonsBox__list,
    reasonsBox__title,
    social,
    socialColumn,
    socialCount,
    socialIcon,
    trainers,
} from './about.module.scss';
import AboutEmotics from '../assets/images/svg/about-emotics.svg';
import Heart from '../assets/images/svg/about-heart.svg';
import HeartLine from '../assets/images/svg/about-heart-line.svg';
import YoutubeIcon from '../assets/images/svg/youtube.svg';
import Facebook from '../assets/images/svg/facebook.svg';
import Instagram from '../assets/images/svg/instagram.svg';

import SEO from '../components/seo';
import Main from '../layouts/main';
import Emotic from '../components/atoms/emotic';
import Title from '../components/atoms/title';
import SubTitle from '../components/atoms/subtitle';
import FloatingIcon from '../components/atoms/floating-icon';
import MainBanner from '../components/organisms/main-banner';
import RelatedList from '../components/organisms/related-list';
import VideoPostPreview from '../components/molecules/video-post-preview';
import BigTitle from '../components/atoms/big-title';
import LinkButton from '../components/atoms/link-button';

const AboutPage = ({ pageContext: { followersCount } }) => {
    const {
        aboutBannerDesktop,
        aboutBannerMobile,
        aboutHeroDesktop,
        aboutHeroMobile,
        aboutPhotoShe,
        iconDiamond,
        iconCat,
        iconNails,
        aboutPhotoHim,
        iconSmile,
        iconBiceps,
        iconFire,
        allMeetUs,
        site,
        // youtubeAni,
    } = useStaticQuery(query);

    const meetUsVideos = allMeetUs
        ? allMeetUs.edges.map((edge) => {
              return edge.node;
          })
        : [];

    const aboutBanners = withArtDirection(getImage(aboutBannerDesktop), [
        {
            image: getImage(aboutBannerMobile),
            media: `(max-width: 420px)`,
        },
    ]);

    const renderReasonBox = (content) => {
        return (
            <div className={reasonsBox}>
                <Title className={reasonsBox__title}>{content.title}</Title>
                <ol className={reasonsBox__list}>
                    {content.items.map((item, index) => (
                        <li key={index} className={reasonsBox__item}>
                            <span className={reasonsBox__item__counter}>{index + 1}</span>
                            <span>{item}</span>
                        </li>
                    ))}
                </ol>
            </div>
        );
    };

    const heroData = {
        fluidImg: withArtDirection(getImage(aboutHeroDesktop), [
            {
                image: getImage(aboutHeroMobile),
                media: `(max-width: 420px)`,
            },
        ]),
        title: 'Poznaj Strefę Przemian',
        subtitle: 'Poznajmy się bliżej!',
    };

    return (
        <Main transparentHeader className={aboutWrapper}>
            <SEO
                title="O nas"
                structuredData={{
                    '@type': 'AboutPage',
                    url: site.siteMetadata.url,
                    image: `${site.siteMetadata.url}${site.siteMetadata.image}`,
                    name: `O nas | ${site.siteMetadata.title}`,
                    description: site.siteMetadata.description,
                }}
            />
            <MainBanner
                bannerData={heroData}
                className={hero}
                contentWrapperClass={hero__content}
                alt={`Banner strony "O nas"`}
            />
            <section className={title}>
                <Emotic emotic={AboutEmotics} className={emotics} />
                <Title Tag="h1">Czym jest Strefa Przemian?</Title>
                <SubTitle className={subtitle}>
                    To wielka społeczność, która stale się powiększa!
                </SubTitle>
            </section>
            <section className={social}>
                {followersCount.facebook > 0 && (
                    <div className={socialColumn}>
                        <Facebook className={socialIcon} />
                        <BigTitle className={socialCount}>
                            {readableCounter(followersCount.facebook)}
                        </BigTitle>
                    </div>
                )}
                {followersCount.instagram > 0 && (
                    <div className={socialColumn}>
                        <Instagram className={socialIcon} />
                        <BigTitle className={socialCount}>
                            {readableCounter(followersCount.instagram)}
                        </BigTitle>
                    </div>
                )}
                {followersCount.youtube > 0 && (
                    <div className={socialColumn}>
                        <YoutubeIcon className={socialIcon} />
                        <BigTitle className={socialCount}>
                            {readableCounter(followersCount.youtube)}
                        </BigTitle>
                    </div>
                )}
            </section>

            <section className={reasons}>
                {renderReasonBox(reasonContentLeft)}
                {renderReasonBox(reasonContentRight)}
            </section>

            <section className={trainers}>
                <div className={aboutSheText}>
                    <Title className={aboutText__title}>Anna Kolasińska</Title>
                    <div className={aboutText__content}>
                        <p>
                            Ruch i aktywność fizyczna pojawiła się w moim życiu, gdy miałam 7 lat i
                            zachwyciłam się tańcem nowoczesnym. Ta pasja doprowadziła mnie do
                            zdobycia wielokrotnego Mistrza Polski w tańcu nowoczesnym, a nawet 4
                            miejsca na arenie międzynarodowej.
                        </p>
                        <p>
                            Słowo dieta nigdy nie było mi obce. Od 13 roku życia zmagałam się z
                            anoreksją i kompulsywnym objadaniem, które w końcu, po wielu latach,
                            zakończyło się hospitalizacją. Właśnie wtedy postanowiłam pomagać innym
                            w walce z tą podstępną chorobą. Ukończyłam liczne kursy, w tym kurs „
                            International sports science attestation” na uczelni międzynarodowej.
                            Gdy zdiagnozowano u mnie najpierw endometriozę, a potem cekalię,
                            zainteresowałam się dietą ketogeniczną. Dzisiaj cieszę się dobrym
                            zdrowiem i formą, wiem jak ją osiągać pomimo trudności i wątpliwości i
                            chcę się tym dzielić z innymi. Jestem żywym dowodem na to, że odpowiedni
                            styl życia może pokonać bardzo groźnie brzmiące choroby.
                        </p>
                    </div>
                    <LinkButton
                        to="/sklep/product-jem-aby-zyc-16"
                        color="yellow"
                        className={button}
                    >
                        E-book "Jem, aby żyć"
                    </LinkButton>
                </div>

                <div className={aboutShePhoto__wrapper}>
                    {/* TODO: Gatsby Image - change to Static Image */}
                    <GatsbyImage
                        className={aboutPhoto}
                        image={getImage(aboutPhotoShe)}
                        alt="Zdjęcie jej"
                    />
                    <FloatingIcon imgData={iconDiamond} className={diamond} />
                    <FloatingIcon imgData={iconCat} className={cat} />
                    <FloatingIcon imgData={iconNails} className={nails} />
                </div>

                <div className={aboutHimPhoto__wrapper}>
                    {/* TODO: Gatsby Image - change to Static Image */}
                    <GatsbyImage
                        className={aboutPhoto}
                        image={getImage(aboutPhotoHim)}
                        alt="Zdjęcie jego"
                    />
                    <FloatingIcon imgData={iconSmile} className={smile} />
                    <FloatingIcon imgData={iconBiceps} className={biceps} />
                    <FloatingIcon imgData={iconFire} className={fire} />
                </div>

                <Heart className={aboutHeart} />
                <HeartLine className={aboutHeartline} />

                <div className={aboutHimText}>
                    <Title className={aboutText__title}>Bartek Szemraj</Title>
                    <div className={aboutText__content}>
                        <p>
                            Jestem związany ze sportem od dziecka - trenowałem karate kyokushin oraz
                            piłkę ręczną. W tej ostatniej udało mu się być finalistą młodzieżowych
                            oraz akademickich mistrzostw polski.
                        </p>
                        <p>
                            Pasja do zdrowego odżywiania zrodziła się, gdy miałem 20 lat i
                            usłyszałem diagnozę – choroba Hashimoto. Od tego czasu skończyłem
                            niezliczone kursy z zakresu dietetyki, dietoterapii oraz suplementacji.
                            Aktualnie kończę studia na kierunku dietetyka kliniczna. Lubię też
                            słuchać, co mówią moi mistrzowie i autorytety za granicą – gdy tylko
                            mogę, zapisuję się na ich wykłady i kursy, np. na uniwersytecie
                            Stanford.
                        </p>
                    </div>
                    <LinkButton
                        to="/sklep/product-post-przerywany-17"
                        color="yellow"
                        className={button}
                    >
                        E-book "Post przerywany"
                    </LinkButton>
                </div>
            </section>
            {meetUsVideos && meetUsVideos.length > 0 && (
                <section className={video}>
                    <RelatedList
                        title="Zobacz co robimy!"
                        subtitle="Regularnie udostępniamy materiały i wiedzę opartą na doświadczeniu."
                    >
                        {meetUsVideos.map((video, index) => (
                            <VideoPostPreview
                                key={index}
                                videoUrl={video.mainVideoUrl}
                                imageUrl={video.mainImageUrl}
                                provider={video.provider}
                                title={video.title}
                            />
                        ))}
                    </RelatedList>

                    <div className={moreVideosWrapper}>
                        <LinkButton
                            to={'https://www.youtube.com/channel/UCR65XPQSlBKYpNsCYXgAk8w'}
                            color={'yellow'}
                            className={moreButton}
                        >
                            Zobacz wszystkie filmy Ani <YoutubeIcon />{' '}
                        </LinkButton>
                        <LinkButton
                            to={'https://www.youtube.com/channel/UCvRhY-GuNPU02pMsh2ejl9w'}
                            color={'yellow'}
                            className={moreButton}
                        >
                            Zobacz wszystkie filmy Bartka <YoutubeIcon />{' '}
                        </LinkButton>
                    </div>
                </section>
            )}

            <section className={banner}>
                <GatsbyImage image={aboutBanners} alt="Banner" />
            </section>
        </Main>
    );
};

const reasonContentLeft = {
    title: 'Poznajmy się!',
    items: [
        'Jesteśmy osobistymi trenerami, którzy wiedzą, że każdy ma swoje własne tempo i możliwości. Pomagamy Ci je poznać',
        'Jesteśmy osobistymi dietetykami, którzy wiedzą, że każdy ma swoją własną dietę. Pomagamy Ci ją odkryć.',
        'Jesteśmy tylko ludźmi, którzy mają swoje problemy i życiowe wyzwania. Dzięki temu, możemy pomagać innym.',
    ],
};
const reasonContentRight = {
    title: 'Dlaczego możesz nam zaufać?',
    items: [
        'Mamy dowody na to, że nasze sposoby działają – sprawdź historie o udanych metamorfozach naszych podopiecznych',
        'Opieramy nasze metody zarówno o naukę i praktykę, jak i doświadczeni naszych osobistych metamorfoz',
        'Cały czas się uczymy – bo gdy pomagamy Wam, pomagamy też sobie',
    ],
};
const readableCounter = (number) => {
    const enFormat = new Intl.NumberFormat('en-UK').format(number).toString();
    if (!enFormat) {
        return '';
    }
    return enFormat.replace(/,/g, '.');
};

const query = graphql`
    {
        site {
            ...siteFields
        }
        youtubeAni: externalLink(linkId: { eq: "youtubeAni" }) {
            linkId
            name
            uri
        }
        allMeetUs(limit: 3) {
            edges {
                node {
                    mainVideoUrl
                    mainImageUrl
                    title
                    provider
                }
            }
        }
        aboutHeroDesktop: file(relativePath: { eq: "about-hero.png" }) {
            childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: BLURRED)
            }
        }
        aboutHeroMobile: file(relativePath: { eq: "about-hero-mobile.png" }) {
            childImageSharp {
                gatsbyImageData(width: 420, quality: 100, layout: CONSTRAINED, placeholder: BLURRED)
            }
        }
        aboutBannerDesktop: file(relativePath: { eq: "about-banner--desktop.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    aspectRatio: 2.58
                )
            }
        }
        aboutBannerMobile: file(relativePath: { eq: "about-banner--mobile.png" }) {
            childImageSharp {
                gatsbyImageData(
                    quality: 100
                    placeholder: BLURRED
                    layout: FULL_WIDTH
                    aspectRatio: 0.93
                )
            }
        }
        aboutPhotoShe: file(relativePath: { eq: "about-ania.png" }) {
            childImageSharp {
                gatsbyImageData(width: 506, quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
        }
        aboutPhotoHim: file(relativePath: { eq: "about-bartek.png" }) {
            childImageSharp {
                gatsbyImageData(width: 506, quality: 100, placeholder: BLURRED, layout: CONSTRAINED)
            }
        }
        iconDiamond: file(relativePath: { eq: "icon-gem-stone.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 38
                    height: 38
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
        iconCat: file(relativePath: { eq: "icon-smiling-cat-with-heart-shaped-eyes.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 32
                    height: 32
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
        iconNails: file(relativePath: { eq: "icon-nail-polish.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 38
                    height: 38
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
        iconSmile: file(relativePath: { eq: "icon-grinning-face.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 36
                    height: 36
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
        iconBiceps: file(relativePath: { eq: "icon-biceps.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 38
                    height: 38
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
        iconFire: file(relativePath: { eq: "icon-fire.png" }) {
            childImageSharp {
                gatsbyImageData(
                    width: 28
                    height: 28
                    quality: 100
                    placeholder: BLURRED
                    layout: FIXED
                )
            }
        }
    }
`;

export default AboutPage;
